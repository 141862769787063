@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Quicksand:wght@300..700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    font-family: "Quicksand", sans-serif;
    scroll-behavior: smooth;
}

.cursive-text{
    font-family: "Dancing Script", cursive;
}

.text-style{
    font-family: "Merriweather", serif;
}

.btn-bg{
    background: #669999;
    background: -webkit-linear-gradient(124deg, #669999 0%, #336699 50%, #330066 100%);
    background: linear-gradient(124deg, #669999 0%, #336699 50%, #330066 100%);
    transition: all 0.4s ease;
}
.btn-bg:hover{
    background: transparent;
    -webkit-box-shadow: 0 0 4.5px 1.5px #0a6efa;
    -moz-box-shadow: 0 0 4.5px 1.5px #0a6efa;
    box-shadow: 0 0 4.5px 1.5px #0a6efa;
}
.bg-hover{
    background: transparent;
    -webkit-box-shadow: 0 0 4.5px 1.5px #0a6efa;
    -moz-box-shadow: 0 0 4.5px 1.5px #0a6efa;
    box-shadow: 0 0 4.5px 1.5px #0a6efa;
}
.about-bg{
    background-image: radial-gradient(#222222 1.5px, transparent 1.5px);
    background-size: 32px 32px;
    background-color: #111111;
}
.about-bg:hover{
    -webkit-box-shadow: 1px 1px 7.5px 6px #5c5a5a;
    -moz-box-shadow: 1px 1px 7.5px 6px #5c5a5a;
    box-shadow: 1px 1px 7.5px 6px #5c5a5a;
    transition: 0.3s ease;
}

.blog-bg{
    background: #fff;
    transition: all 0.4s ease-in-out;
}
.blog-bg:hover{
    -webkit-box-shadow: 1px 1px 7.5px 6px #5c5a5a;
    -moz-box-shadow: 1px 1px 7.5px 6px #5c5a5a;
    box-shadow: 1px 1px 7.5px 6px #5c5a5a;
    transform: scale(1.05);
}

.testimonial-bg{
    background-image: radial-gradient(#222222 1.5px, transparent 1.5px);
    background-size: 32px 32px;
    background-color: #111111;
}

.bg-pattern{
    background-image: linear-gradient(#111111 1px, transparent 1px), linear-gradient(to right, #111111 1px, transparent 1px);
    background-size: 32px 32px;
    background-color: #000000;
}

.bg{
    background: transparent;
    transition: 0.5s ease-in-out;
}
.bg:hover{
    -webkit-box-shadow: 0 0 19px 9px #0a6efa;
    -moz-box-shadow: 0 0 19px 9px #0a6efa;
    box-shadow: 0 0 19px 9px #0a6efa;
}

.home-bg {
    background: rgba(51, 204, 255, 0.174); /* Fallback color with reduced opacity */
    background: -webkit-linear-gradient(0deg, rgba(51, 204, 255, 0.418) 0%, rgba(153, 51, 255, 0.8) 100%);
    background: linear-gradient(0deg, rgba(51, 204, 255, 0.416) 0%, rgba(153, 51, 255, 0.8) 100%);
}

.top-bg{
    width: 700px;
    height: 400px;
    opacity: 0.3;
    background: rgba(18, 18, 18, 0);
    background: -webkit-linear-gradient(30deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0) 50%, #bc48ff 100%);
    background: linear-gradient(30deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0) 50%, #bc48ff 100%);
}

.bottom-bg{
    background: rgba(0, 38, 255, 0.283);
    background: -webkit-radial-gradient(circle, rgba(0, 38, 255, 0.283) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
    background: radial-gradient(circle, rgba(0, 38, 255, 0.283) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
}

.bottom-right-bg{
    background: rgba(0, 251, 255, 0.38);
    background: -webkit-radial-gradient(circle, rgba(0, 251, 255, 0.38) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
    background: radial-gradient(circle, rgba(0, 251, 255, 0.38) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
}
 

.cursive-txt{
    font-family: "Grey Qo", cursive;
}

.progress {
    position: relative;
    width: 89.6px;
    height: 89.6px;
    border-radius: 50%;
    border: 4.5px solid #474bff;
    box-sizing: content-box;
 }
 
.progress:before {
    content: "";
    position: absolute;
    width: 4.5px;
    height: 38.1px;
    top: 9px;
    left: 42.6px;
    background: #474bff;
    border-radius: 2.2px;
    transform-origin: 2.25px 37px;
    animation: progress-t59zy9 2s linear infinite;
}
 
.progress:after {
    content: "";
    position: absolute;
    width: 4.5px;
    height: 24.6px;
    top: 22.4px;
    left: 42.6px;
    background: #474bff;
    border-radius: 2.2px;
    transform-origin: 2.25px 23.5px;
    animation: progress-t59zy9 12s linear infinite;
}

@keyframes progress-t59zy9 {
    0% {
        tranform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.custom-scrollbar::-webkit-scrollbar {
    width: 6px; /* Width of the vertical scrollbar */
    height: 6px; /* Height of the horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ff7f50; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
    border: 0px solid #ff7f50; /* Space between the thumb and edge */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #ff4500; /* Darker color on hover */
}

/* Firefox specific scrollbar styling */
.custom-scrollbar {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #ff7f50 transparent; /* thumb color, track color */
}